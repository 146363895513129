import * as React from 'react';

import Avatar from '@mui/material/Avatar';

import Container from '@mui/material/Container';
import { useState, useEffect } from "react";

import { Box } from '@mui/material';

export default function Asoc(props) {
    const [asoc,setAsoc]=useState([]);
    
    useEffect(() => {
        
        if(props.data!=null){
            setAsoc(props.data.asociados);
        }
      }, [props]);

    
      
    function stringAvatar(name,col) {
        return {
          sx: {
            bgcolor: col,
            width: 100, height: 100,
            fontSize:"2rem"
            },
          children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }
    return (
    <section id="asoc">
        <Container maxWidth="false" sx={{display:'flex',justifyContent: 'center' , alignItems: 'center',flexDirection:'column',flexWrap:'wrap' }}>
            <h1>Nuestros Asociados</h1>
            <Container maxWidth="false" sx={{display:'flex',justifyContent: 'center' , alignItems: 'center',flexDirection:'row',flexWrap:'wrap' }}>
                
                {asoc.map((item)=>
                // <Flip bottom>
                <Box key={item.name} sx={{ width:340,maxWidth:340,display:'flex',justifyContent: 'center' , alignItems: 'center',flexDirection:'column', textAlign:"center", margin:1}} elevation={10}>
                
                    <Avatar {...stringAvatar(item.name,item.col)} />
                    <div >{item.aux}</div>
                    
                    
                </Box>
                // </Flip>
                )
                
                }
            </Container>
        </Container>
      </section>
    );}