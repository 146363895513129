import React, { Component } from "react";

import CardMedia from '@mui/material/CardMedia';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
let id = 0;
class Portfolio extends Component {
  render() {
    if (!this.props.data) return null;

    const projects = this.props.data.projects.map(function (projects) {
      let projectImage = "images/portfolio/" + projects.image;

      return (
        // <Fade bottom duration={1000}>
       
          <Card key={id++} sx={{ maxWidth: 1000,margin:3 }} elevation={10}>
              <CardMedia
                    component="img"
                    height="140"
                    src={projectImage}
                    alt="green iguana"
                />
            {/* <div style={{ textAlign: "center" }}>{projects.title}</div> */}
          </Card>
        
        // </Fade>
      );
    });

    return (
      <section id="portfolio">
        
          
              <h1>Nuestro Estudio</h1>

              <Container maxWidth="false" sx={{display:'flex',justifyContent: 'center' , alignItems: 'center',flexDirection:'row',flexWrap:'wrap' }}>
                {projects}
                </Container>
            
        
      </section>
    );
  }
}

export default Portfolio;
