import React, { useState ,useEffect} from "react";

import * as emailjs from "emailjs-com";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
export const Contact=(props)=>{
    const Alert = React.forwardRef(function Alert(props, ref) {
      return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
    const [open, setOpen] = useState(false);
    const [data,setData]=useState({
      name:"",
      street:"",
      city:"",
      state:"",
      zip:"",
      phone:"",
      message:""
    }
    )
    const [formData, setFormdata] = useState({
      email: "",
      name: "",
      message: "",
      loading: false,
      show: false,
      alertmessage: "",
      variant: "",
    });
    const handleSubmit = (e) => {
      
      e.preventDefault();
      setFormdata({loading: true});
  
      const templateParams = {
        from_name: formData.email,
        user_name: formData.name,
        to_name: "Estudio Killing",
        message: formData.message,
      };
  
      emailjs
        .send(
          "service_6euytyi",
          "template_dk3pw9y",
          templateParams,
          "YDQIP8toJxdKFnedq"
        )
        .then(
          (result) => {
            console.log(result.text);
            setFormdata({
              loading: false,
              alertmessage: "Enviado! Gracias por ponerte en contacto",
              variant: "success",
              show: true,
            });
          },
          (error) => {
            console.log(error.text);
            setFormdata({
              alertmessage: `Fallo al enviar!,${error.text}`,
              variant: "error",
              show: true,
            });
            document.getElementsByClassName("co_alert")[0].scrollIntoView();
          }
        );
    };
  
    const handleChange = (e) => {
      setFormdata({
        ...formData,
        [e.target.name]: e.target.value,
      });
    };
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setFormdata({
        ...formData,
        show: false,
      });
    };
    const handleCloseloading = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setFormdata({
        ...formData,
        loading: false,
      });
    };
    useEffect(() => {
        
      if(props.data!=null){
        setData(
          {name:props.data.name,
          street:props.data.address.street,
          city:props.data.address.city,
          state:props.data.address.state,
          zip:props.data.address.zip,
          phone:props.data.phone,
          message:props.data.contactmessage}
        );
      }
    }, [props]);
    return (
      <section id="contact">
        {/* <Fade bottom duration={1000}> */}
          <div className="row section-head">
            <div className="two columns header-col">
              <h1>
                <span>Ponerse en contacto.</span>
              </h1>
            </div>

            <div className="ten columns">
              <p className="lead">{data.message}</p>
            </div>
          </div>
        {/* </Fade> */}

        <div className="row">
          {/* <Slide left duration={1000}> */}
            <div className="eight columns">
              <form onSubmit={handleSubmit} name="contactForm">
                <fieldset>
                  <div>
                    <label htmlFor="contactName">
                      Nombre <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      defaultValue=""
                      size="35"
                      id="name"
                      name="name"
                      onChange={handleChange}
                    />
                  </div>

                  <div>
                    <label htmlFor="contactEmail">
                      Email <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      defaultValue=""
                      size="35"
                      id="email"
                      name="email"
                      onChange={handleChange}
                    />
                  </div>

                  
                  <div>
                    <label htmlFor="contactMessage">
                      Mensaje <span className="required">*</span>
                    </label>
                    <textarea
                      cols="50"
                      rows="15"
                      id="message"
                      name="message"
                      onChange={handleChange}
                    ></textarea>
                  </div>

                  <div>
                    <button className="submit">Enviar</button>
                  </div>
                </fieldset>
              </form>

              <div id="message-warning"> Ocurrio un error</div>
              <div id="message-success">
                <i className="fa fa-check"></i>Tu mensaje fue enviado. Muchas gracias!
                <br />
              </div>
            </div>
          {/* </Slide> */}

          {/* <Slide right duration={1000}> */}
            <aside className="four columns footer-widgets">
              <div className="widget widget_contact">
                <h4>Datos de contacto</h4>
                <p className="address">
                  {data.name}
                  <br />
                  {data.street} <br />
                  {data.city}, {data.state} {data.zip}
                  <br />
                  <span>{data.phone}</span>
                </p>
              </div>

              <div className="widget widget_tweets">
                <h4 className="widget-title">Nuestra Direccion</h4>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3285.7827396168814!2d-58.461706284771424!3d-34.55905588047109!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb538faec8d0d%3A0x1d3e362559d97a4d!2sEstudio%20Killing!5e0!3m2!1ses-419!2sar!4v1578399386951!5m2!1ses-419!2sar" 
                width={240} height={300} ></iframe>
              </div>
            </aside>
          {/* </Slide> */}
        </div>
        <Snackbar open={formData.show} key={"bottom" + "center"} anchorOrigin={{vertical: "bottom", horizontal: "center"}} autoHideDuration={6000} onClose={handleClose} >
          <Alert onClose={handleClose} severity={formData.variant} sx={{ width: '100%', fontSize:14 }}>
            {formData.alertmessage}
          </Alert>
      </Snackbar>
      <Snackbar open={formData.loading} key={"bottom" + "center"} anchorOrigin={{vertical: "bottom", horizontal: "center"}} autoHideDuration={6000} onClose={handleCloseloading} >
          <Alert onClose={handleClose} severity="info" sx={{ width: '100%', fontSize:14 }}>
            Enviando mensaje...
          </Alert>
      </Snackbar>
      </section>
    );
  }

  export default Contact;

