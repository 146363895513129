import React, { useState, useEffect } from "react";


const Header=(props)=>{
  const [data,setData]=useState({});
    useEffect(() => {
        
      if(props.data!=null){
        setData({
          name:props.data.name,
          description:props.data.description,
          name2:props.data.name2
        });
      }
    }, [props]);
    
    
    return (
      <header id="home">
        {/* <ParticlesBg type="cobweb"  color="#227DDD"  bg={true} /> */}

        <nav id="nav-wrap">
          <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
            Ver navegacion
          </a>
          <a className="mobile-btn" href="#home" title="Hide navigation">
            Esconder navegacion
          </a>

          <ul id="nav" className="nav">
            <li className="current">
              <a className="smoothscroll" href="#home">
                Inicio
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#about">
                Nosotros
              </a>
            </li>

            

            <li>
              <a className="smoothscroll" href="#practiceareas">
                Áreas
              </a>
            </li>
            <li>
              <a className="smoothscroll" href="#resume">
                Servicios
              </a>
            </li>
            
            <li>
              <a className="smoothscroll" href="#portfolio">
                Estudio
              </a>
            </li>
            <li>
              <a className="smoothscroll" href="#asoc">
                Asociados
              </a>
            </li>
            <li>
              <a className="smoothscroll" href="#contact">
                Contacto
              </a>
            </li>
          </ul>
        </nav>

        <div className="row banner">
          <div className="banner-text">
            {/* <Bounce  top duration={1200}> */}
              <h1 className="responsive-headline">{data.name}</h1>
              <h1 className="responsive-headline">{data.name2}</h1>
            {/* </Bounce > */}
            {/* <Bounce  bottom duration={2000}> */}
              <h3>{data.description}.</h3>
            {/* </Bounce > */}
            <hr />
            {/* <Fade bottom duration={2000}>
              <ul className="social">
                <a href={project} className="button btn project-btn">
                  <i className="fa fa-book"></i>Project
                </a>
                <a href={github} className="button btn github-btn">
                  <i className="fa fa-github"></i>Github
                </a>
              </ul>
            </Fade> */}
          </div>
        </div>

        <p className="scrolldown">
          <a className="smoothscroll" href="#about">
            <i className="icon-down-circle"></i>
          </a>
        </p>
      </header>
    );
  }


export default Header;
