import * as React from 'react';
import { Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import Typography from '@mui/material/Typography';

import Container from '@mui/material/Container';
import { useState, useEffect } from "react";

import Icon from '@mui/material/Icon';










export default function PracticeAreas(props) {
    const [pareas,setPareas]=useState([]);
    
    useEffect(() => {
        
        if(props.data!=null){
            setPareas(props.data.areas);
        }
      }, [props]);
    return (
    <section id="practiceareas">
        <Container maxWidth="false" sx={{display:'flex',justifyContent: 'center' , alignItems: 'center',flexDirection:'row',flexWrap:'wrap' }}>
            {pareas.map((item)=>
            // <Fade top>
            <Card key={item.name} sx={{ maxWidth: 330,margin:3 }} elevation={10}>
                <Box sx={{display:'flex',justifyContent: 'center' , alignItems: 'center',flexDirection:'row',flexWrap:'wrap',margin:4 }}> 
                    <Icon sx={{"font-size":"10rem", color:item.iconcolor}}>{item.icon}</Icon>
                </Box>
                
                <CardContent>
                    <Box sx={{display:'flex',justifyContent: 'center' , alignItems: 'center',flexDirection:'row',flexWrap:'wrap'}}> 
                        <Typography gutterBottom color={"#3969a0"} variant="h4" component="div" fontWeight={600}>
                        {item.name}
                        </Typography>
                    </Box>

                    <Typography variant="h5" color="text.secondary">
                    {item.description}
                    </Typography>
                </CardContent>
                
            </Card>
            // </Fade>
            )
            
            }
        </Container>
      </section>
    );}